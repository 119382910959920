import React from 'react'
import { Container, Flex, Box, Badge, Link } from 'theme-ui'
import ContentContainer from '@ui-components/ContentContainer'
import Reveal from '@ui-components/Reveal'
import Divider from '@ui-components/Divider'
import ContentText from '@ui-components/ContentText'
import ContentImages from '@ui-components/ContentImages'
import ContentButtons from '@ui-components/ContentButtons'
import WithDefaultContent from '@ui-blocks/WithDefaultContent'
import { FaRegClock } from 'react-icons/fa'

const styles = {
  wrapper: {
    flex: [`100%`, null, null, 1],
    minWidth: 300,
    maxWidth: [`none`, null, null, 500],
    cursor: `pointer`,
    p: 3
  },
  card: {
    overflow: `hidden`,
    height: `full`
  },
  content: {
    alignItems: `stretch`,
    flexDirection: [`row`, null, null, `column`],
    height: `full`
  },
  body: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    flex: 1,
    p: 4
  },
  footerWrapper: {
    alignItems: `center`
  },
  postInfo: {
    flex: 1,
    flexWrap: `wrap`,
    justifyContent: `space-between`,
    color: `omega`,
    ml: 3
  },
  imageWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: `block`,
    height: `full`
  },
  image: {
    display: [`none`, `block`],
    height: `full`,
    bg: `omegaLighter`,
    borderRadius: `default`,
    minHeight: `15rem`,
    div: {
      p: `0 !important`
    }
  },
  avatar: {
    size: 42,
    bg: `omegaLighter`,
    borderRadius: `full`,
    borderStyle: `solid`,
    borderWidth: `md`,
    borderColor: `omegaLighter`,
    boxSizing: `content-box`,
    img: {
      objectPosition: 'top center !important'
    }
  }
}

const BlogBlock01 = ({ content: { text, collection, buttons }, datas, locale }) => {

  function sliceString(type, value) {
    if (type) {
      return value.length > 80 ? value.slice(0, 80 - 1) + '&hellip;' : value
    }
    return value.length > 65 ? value.slice(0, 65 - 1) + '&hellip;' : value
  }

  return (
    <Container>
      <Box sx={{ textAlign: `center` }}>
        <ContentText
          content={text}
          sx={{ pt: [4, 2] }}
        />
      </Box>
      <Divider />
      {datas && (
        <Reveal effect='fadeIn'>
          <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -3 }}>
            {datas.map((data, container, text, images, avatar, buttons, index) => (
              <Box key={`item-${index}`} sx={styles.wrapper}>
                <ContentContainer
                  content={{
                    "variant": "cards.interactive",
                    "link": {
                      "type": "PAGE",
                      "link": `/pages/blogView?id=${data.id}`
                    }
                  }}
                  variant='cards.interactive'
                  sx={styles.card}
                >
                  <Flex as='article' sx={styles.content}>
                    {/* Image */}
                    <Box sx={{ flex: [0, 1], m: 2, mb: [null, null, null, 0] }}>
                      <Box sx={styles.imageWrapper}>
                        <ContentImages
                          content={{
                            images: [
                              {
                                "src": { publicURL: "https://crossjourneystorage.blob.core.windows.net/crossjourneycontainer/" + data.image, extension: "svg" },
                                "alt": "alt text"
                              }
                            ]
                          }}
                          sx={styles.image}
                          imageEffect='fadeIn'
                        />
                      </Box>
                    </Box>
                    <Box sx={styles.body}>
                      {/* Category */}
                      <Box sx={{ display: `inline-block` }}>
                        <Box mb='3'>
                          <Badge
                            variant='tag'
                            sx={{ bg: `alphaLighter` }}
                            color='alphaDark'
                          >
                            {locale === 'en' ?
                              (data.en_category.length > 35 ? data.en_category.slice(0, 35 - 1) + '...' : data.en_category) :
                              (data.fr_category.length > 35 ? data.fr_category.slice(0, 35 - 1) + '...' : data.fr_category)
                            }
                          </Badge>
                        </Box>
                      </Box>
                      {/* Title */}
                      <ContentText
                        content={{
                          "text": locale === 'en' ? sliceString(0, data.en_short_description) : sliceString(0, data.fr_short_description),
                          "color": "omegaDarker",
                          "variant": "h6"
                        }}
                        sx={{ flex: [0, 0, `auto`] }}
                      />
                      {/* Excerpt */}
                      <ContentText
                        content={{
                          "text": locale === 'en' ? sliceString(1, data.en_long_description) : sliceString(1, data.fr_long_description),
                        }}
                        variant='small'
                        sx={{ flex: `auto`, mb: 3 }}
                      />
                      {/* Footer */}
                      <Box sx={{ variant: `text.small` }}>
                        <Flex sx={styles.footerWrapper}>
                          <ContentImages
                            content={{
                              images: [
                                {
                                  alt: "alt text",
                                  "src": {
                                    publicURL: "https://crossjourneystorage.blob.core.windows.net/crossjourneycontainer/" + data.avatars,
                                    extension: "png",
                                    childImageSharp: {
                                      gatsbyImageData: {
                                        height: 132,
                                        width: 132,
                                        layout: "constrained",
                                        images: {
                                          fallback: {
                                            src: "https://crossjourneystorage.blob.core.windows.net/crossjourneycontainer/" + data.avatars
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              ]
                            }}
                            sx={styles.avatar}
                            imageEffect='fadeIn'
                          />
                          <Flex sx={styles.postInfo}>
                            {/* Author */}
                            <ContentText
                              content={{
                                "text": locale === 'en' ? data.en_authore : data.fr_authore,
                                "color": "omegaDark",
                                "variant": 'h6'
                              }}
                              sx={{
                                display: `inline-block`,
                                flexBasis: `full`
                              }}
                            >
                            </ContentText>
                            {/* Info */}
                            <Flex sx={{ alignItems: `center` }}>
                              <ContentText
                                content={{
                                  "text": locale === 'en' ? data.updated_date_en : data.updated_date_fr
                                }}
                                sx={{ display: `inline-block` }}
                                mr='2'
                              >
                                {text?.[4]?.textGroup?.[0]}
                              </ContentText>
                              <Box sx={{ ml: 15 }} >
                                {<FaRegClock />}
                                <ContentText
                                  content={{
                                    "text": locale === 'en' ? data.updated_time_en : data.updated_time_fr
                                  }}
                                  sx={{ display: `inline-block` }}
                                  ml='2'
                                />
                              </Box>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Box>
                    </Box>
                  </Flex>
                </ContentContainer>
              </Box>
            )
            )}
          </Flex>
        </Reveal>
      )}
    </Container>
  )
}

export default WithDefaultContent(BlogBlock01)
