import React, { useState, useEffect, useCallback } from 'react'
import { graphql } from 'gatsby'
import { Container, Spinner } from 'theme-ui'
import { injectIntl, changeLocale } from 'gatsby-plugin-intl'
import Reveal from '@ui-components/Reveal'
import Layout from '@ui-layout/Layout'
import Seo from '@ui-components/Seo'
import Divider from '@ui-components/Divider'
import ModalWithTabs from '@ui-blocks/Modal/Block01'
import ModalSimple from '@ui-blocks/Modal/Block02'
import ModalPrivacy from '@ui-blocks/Modal/Block03'
import ModalCookies from '@ui-blocks/Modal/Block03'
import Header from '@ui-blocks/Header/Block01'
import Hero from '@ui-blocks/Hero/Block01'
import Process from '@ui-blocks/Features/Block03'
import Blog from '@ui-blocks/Blog/Block03'
import Footer from '@ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes, converDate } from '@blocks-helpers'
import theme from './home/_theme'
import styles from './home/_styles'

var count = 0;

const HomePage = props => {
    const { allBlockContent } = props.data
    const { locale } = props.intl
    const [content, setContent] = useState(
        normalizeBlockContentNodes(allBlockContent?.nodes)
    )
    const [loder, setLoder] = useState(false)
    const [blog, setBlog] = useState()

    useEffect(() => {
        if (!count) changeLocale('fr');
        count++;
        const script = document.createElement("script");
        script.id = "Cookiebot";
        script.src = "https://consent.cookiebot.com/uc.js";
        script.async = true;
        script.type = "text/javascript";
        script.setAttribute("id", "Cookiebot");
        script.setAttribute("data-cbid", "b4c6ff2f-3209-4e15-a3a7-1840c7fd5c02");
        script.setAttribute("data-blockingmode", "auto");
        document.body.appendChild(script);
    }, []);

    const getValues = useCallback(() => {
        setLoder(true)
        fetch(`https://crossjourney.org/api/Page?name=pages/home&lang=${locale}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                converDate(data).then((datas) => {
                    setContent(datas);
                    // console.log('data', datas, normalizeBlockContentNodes(allBlockContent?.nodes));
                });
            })
            .catch(error => {
                console.log('error', error)
            })
            .finally(() => setLoder(false))
    }, [locale])

    const getBlogs = useCallback(() => {
        setLoder(true)
        fetch(`https://crossjourney.org/api/Blog`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                const sortedData = data?.sort((a, b) => {
                    return new Date(b.updated_at) - new Date(a.updated_at)
                })
                setBlog(sortedData.splice(0, 3));
                // console.log('blog', sortedData);
            })
            .catch(error => {
                console.log('error', error)
            })
            .finally(() => setLoder(false))
    }, [])

    useEffect(() => {
        getValues()
        getBlogs()
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [getValues, getBlogs])

    if (!content || loder)
        return (
            <Reveal effect='fadeInDown'>
                <Spinner size='64' color='alpha' />
            </Reveal>
        )
    return (
        <Layout theme={theme} {...props}>
            <Seo title='Home' />
            {/* Modals */}
            <ModalSimple content={content['search']} />
            <ModalPrivacy content={content['privacyNotice']} />
            <ModalPrivacy content={content['termsUse']} />
            {/* Blocks */}
            <Header content={content['header-light']} menuJustify='space-between' />
            <Divider space='5' />
            <Container variant='full' sx={styles.heroContainer}>
                <Hero content={content['hero']} reverse />
            </Container>
            <Divider space='5' />
            <Divider space='5' />
            <Divider space='5' />
            {/* <Divider space='5' /> */}
            <Container variant='full' sx={styles.processContainer}>
                <Process content={content['process']} />
            </Container>
            <Divider space='5' />
            {/* <Divider space='5' /> */}
            <Blog content={content['latest-blogs']} datas={blog} locale={locale} />
            <Divider space='5' />
            <Footer content={content['footer']} />
        </Layout>
    )
}

export const query = graphql`
  query miscIndexBlockContent {
    allBlockContent(filter: { page: { in: ["pages/home", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default injectIntl(HomePage)
